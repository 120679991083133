// GUI.js
import React, { useRef, useEffect, useState } from 'react';
import './GUI.css'; // Import GUI.css for styling

function GUI({ blockList }) {
  const guiBlock = blockList && blockList.find(block => block.flow === 'gui');
  const [guiElements, setGuiElements] = useState([]);

  useEffect(() => {
    if (guiBlock && guiBlock.gui) {
      setGuiElements(guiBlock.gui);
    } else {
      setGuiElements([]);
    }
  }, [guiBlock]);

  const handleButtonClick = (action) => {
    console.log(`Button "${action}" clicked`);
    // Implement actions based on button.action here (e.g., startSimulation, stopSimulation)
    if (action === 'startSimulation') {
      console.log('Simulation started');
      // Add logic to start simulation here
    } else if (action === 'stopSimulation') {
      console.log('Simulation stopped');
      // Add logic to stop simulation here
    }
  };

  const handleSliderChange = (dataBinding, value) => {
    console.log(`Slider "${dataBinding}" changed to ${value}`);
    // Implement data binding logic here to update scene parameters
    if (dataBinding === 'physics.gravity.y') {
      // Example: Update gravity - actual implementation depends on your physics engine
      console.log(`Setting gravity Y to ${value}`);
    }
  };

  const handleCheckboxChange = (dataBinding, checked) => {
    console.log(`Checkbox "${dataBinding}" changed to ${checked}`);
    // Implement data binding logic here to update scene parameters
    if (dataBinding === 'rendering.shadowsEnabled') {
      // Example: Enable/disable shadows - actual implementation depends on your rendering setup
      console.log(`Shadows enabled: ${checked}`);
    }
  };


  const renderGUIElement = (element, index) => {
    switch (element.type) {
      case 'panel':
        return (
          <div key={index} className="gui-panel" style={element.style}>
            {element.title && <h3>{element.title}</h3>}
            {element.children && element.children.map((child, childIndex) => renderGUIElement(child, childIndex))}
          </div>
        );
      case 'button':
        return (
          <button
            key={index}
            className="gui-button"
            style={element.style}
            onClick={() => handleButtonClick(element.action)}
          >
            {element.label}
          </button>
        );
      case 'slider':
        return (
          <div key={index} className="gui-slider-container" style={element.style}>
            <label style={element.labelStyle}>{element.label}</label>
            <input
              type="range"
              className="gui-slider"
              style={element.inputStyle}
              min={element.min}
              max={element.max}
              step={element.step}
              defaultValue={element.value}
              onChange={(e) => handleSliderChange(element.dataBinding, parseFloat(e.target.value))}
            />
            <span className="gui-slider-value" style={element.valueStyle}>{element.value}</span>
          </div>
        );
      case 'checkbox':
        return (
          <div key={index} className="gui-checkbox-container" style={element.style}>
            <input
              type="checkbox"
              className="gui-checkbox"
              style={element.inputStyle}
              defaultChecked={element.value}
              onChange={(e) => handleCheckboxChange(element.dataBinding, e.target.checked)}
            />
            <label style={element.labelStyle}>{element.label}</label>
          </div>
        );
      case 'label':
        return (
          <div key={index} className="gui-label" style={element.style}>
            {element.label} <span className="gui-data-display">{/* Data display will be updated dynamically */}</span>
          </div>
        );
      default:
        return <div key={index}>Unknown GUI element type: {element.type}</div>;
    }
  };

  return (
    <div className="gui-container">
      {guiElements.map((element, index) => renderGUIElement(element, index))}
    </div>
  );
}

export default GUI;