// Ground.js
import React from 'react';
import { PlaneGeometry, MeshStandardMaterial, Mesh, TextureLoader, RepeatWrapping, MathUtils } from 'three';
import * as THREE from 'three';
import { RigidBody, CuboidCollider } from '@react-three/rapier';

function Ground({ blocks }) { // Still expects blocks as an array
  const groundBlocks = blocks || []; // Use blocks prop directly

  // Log to verify ground blocks are received
  console.log('Ground blocks received:', groundBlocks);

  return (
    <>
      {groundBlocks.map((groundBlock, index) => { // Iterate over groundBlocks array
        if (groundBlock.flow === 'ground') { // Check if it's a ground block based on 'flow'

          const geometryData = groundBlock.geometries.find(geo => geo.uuid === groundBlock.object.geometry);
          const materialData = groundBlock.materials.find(mat => mat.uuid === groundBlock.object.material);
          const userData = groundBlock.object.userData;

          if (!geometryData) {
            console.error('Geometry data not found for ground block:', groundBlock.object.geometry);
            return null;
          }

          const groundWidth = geometryData.width;
          const groundHeight = geometryData.height;

          let materialProperties = {};

          let texture = null;
          if (userData?.file_url) { // Use file_url from userData for texture
            texture = new TextureLoader().load(userData.file_url);
            texture.wrapS = RepeatWrapping;
            texture.wrapT = RepeatWrapping;
            // Assuming default repeat values if not specified in API, or you can add them to userData if needed
            texture.repeat.set(4, 4); // Example repeat values, adjust as needed, e.g. based on ground size
            materialProperties.map = texture;
          }

          materialProperties.roughness = materialData?.roughness || 0.5; // Use materialData if available, otherwise defaults
          materialProperties.metalness = materialData?.metalness || 0;  // Use materialData if available, otherwise defaults
          const material = new MeshStandardMaterial(materialProperties);

          const geometry = new PlaneGeometry(groundWidth, groundHeight, 1, 1);

          const GroundPlane = () => {
            // Convert rotation from userData to radians if present, default to 0 if not
            const rotationDegrees = userData?.rotation || [90, 0, 0]; // Default rotation if not in userData
            const rotationRadians = rotationDegrees.map((degree, index) => {
              if (index === 0) return MathUtils.degToRad(-degree); // Rotate around X axis, and invert direction to align with threejs plane facing
              return MathUtils.degToRad(degree);
            });


            const position = userData?.position || [0, 0, 0];


            return (
              <RigidBody
                type="fixed"
                position={position}
                rotation={rotationRadians} // Apply rotation from userData
              >
                <mesh
                  position={[0, 0, 0]}
                  rotation={[0, 0, 0]}
                  receiveShadow={true} // Assuming ground should receive shadow, adjust if needed
                  geometry={geometry}
                  material={material}
                />
                <CuboidCollider
                  args={[groundWidth / 2, 0.01, groundHeight / 2]}
                  restitution={0.5} // Default physics properties, adjust as needed, could be from API too
                  friction={0.8}    // Default physics properties, adjust as needed, could be from API too
                />
              </RigidBody>
            );
          };

          return <GroundPlane key={groundBlock.item_id || index} />; // Use item_id if available, otherwise index
        }
        return null; // Return null if the block is not a ground block
      })}
    </>
  );
}

export default Ground;