import React, { useRef, useState, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { RigidBody, CuboidCollider, BallCollider, CylinderCollider } from '@react-three/rapier';

function renderGeometry(geometry) {
    const geometryArgs = geometry.args;
    const args = Array.isArray(geometryArgs) ? geometryArgs : (geometryArgs !== undefined && geometryArgs !== null ? [geometryArgs] : []);

    switch (geometry.type) {
        case 'sphereGeometry':
            return <sphereGeometry args={args} />;
        case 'boxGeometry':
            return <boxGeometry args={args} />;
        case 'planeGeometry':
            return <planeGeometry args={args} />;
        case 'coneGeometry':
            return <coneGeometry args={args} />;
        case 'cylinderGeometry':
            return <cylinderGeometry args={args} />;
        case 'torusGeometry':
            return <torusGeometry args={args} />;
        default:
            return null;
    }
}

// --- Separate Component for GLB Models ---
function GLBGenericModel({ block }) {
    const file_url = block.file_url;
    // Extract position, scale, and rotation from block.object or use defaults
    let position = block.object?.position;
    if (position && typeof position === 'object' && !Array.isArray(position)) {
        position = [position.x || 0, position.y || 0, position.z || 0];
    } else {
        position = position || [0, 0, 0];
    }

    let scale = block.object?.scale;
    if (scale && typeof scale === 'object' && !Array.isArray(scale)) {
        scale = [scale.x || 1, scale.y || 1, scale.z || 1];
    } else {
        scale = scale || [1, 1, 1];
    }

    let rotation = block.object?.rotation;
    if (rotation && typeof rotation === 'object' && !Array.isArray(rotation)) {
        rotation = [rotation.x || 0, rotation.y || 0, rotation.z || 0];
    } else {
        rotation = rotation || [0, 0, 0];
    }

    const [gltf, setGltf] = useState(null);
    const [error, setError] = useState(null);
    const rigidBodyRef = useRef();

    useEffect(() => {
        if (!file_url) {
            console.warn("GLBGenericModel: URL is missing for block:", block);
            setError(new Error("URL is missing"));
            return;
        }

        let isMounted = true;

        const loader = new GLTFLoader();

        console.log("GLB model loading started for URL:", file_url); // ADDED: Log GLB loading start

        loader.load(
            file_url,
            (loadedGltf) => {
                if (isMounted) {
                    // Apply encoding and texture properties to GLB model materials
                    loadedGltf.scene.traverse((child) => {
                        if (child.isMesh && child.material) {
                            const material = child.material;

                            // Ensure material is not undefined and has properties
                            if (material) {
                                // Check if it's an array of materials (for multi-material objects)
                                const materials = Array.isArray(material) ? material : [material];

                                materials.forEach(mat => {
                                    if (mat) { // Check if material in array is not undefined
                                        // Set side to double if needed, sometimes GLBs have backface culling issues
                                        mat.side = THREE.FrontSide; // Or THREE.DoubleSide if you see missing faces

                                        // Ensure textures have proper encoding - crucial for correct colors
                                        for (const textureName in mat) {
                                            if (mat[textureName] && mat[textureName].isTexture) {
                                                mat[textureName].encoding = THREE.SRGBColorSpace;
                                                mat[textureName].needsUpdate = true; // Important to apply encoding change
                                            }
                                        }

                                        // Basic material properties - adjust these as needed, or based on your GLB's intended look
                                        if (mat.isMeshStandardMaterial) {
                                            mat.roughness = mat.roughness !== undefined ? mat.roughness : 0.8; // Default roughness
                                            mat.metalness = 0.0; // Force metalness to 0
                                        }

                                        mat.needsUpdate = true; // Ensure material updates in the scene
                                    }
                                });
                            }
                        }
                    });

                    setGltf(loadedGltf);
                    setError(null);
                    console.log("GLB model loaded successfully for URL:", file_url); // ADDED: Log GLB loading success
                }
            },
            undefined,
            (loadError) => {
                if (isMounted) {
                    console.error("Error loading GLB model:", loadError, "URL:", file_url, "Block:", block);
                    setError(loadError);
                    setGltf(null);
                    console.log("GLB model loading failed for URL:", file_url, "Error:", loadError); // ADDED: Log GLB loading failure
                }
            }
        );

        return () => {
            isMounted = false;
        };

    }, [file_url, block]); // Added block to dependency array for props update

    if (error) {
        return null;
    }

    if (!gltf) {
        return null;
    }

    const physicsEnabled = block.physics && block.physics.enabled;
    const isStatic = physicsEnabled && block.physics.mass === 0;
    const mass = physicsEnabled ? block.physics.mass : block.physics?.mass !== undefined ? block.physics.mass : 1; // default mass 1 if physics enabled but mass is undefined
    const restitution = physicsEnabled ? block.physics.restitution : 0.2;
    const friction = physicsEnabled ? block.physics.friction : 0.5;

    if (physicsEnabled) {
        return (
            <RigidBody
                ref={rigidBodyRef}
                position={position}
                scale={scale}
                rotation={rotation}
                type={isStatic ? "fixed" : "dynamic"}
                mass={mass}
                restitution={restitution}
                friction={friction}
                linearDamping={block.physics.linearDamping || 0.01}
                angularDamping={block.physics.angularDamping || 0.01}
            >
                {/* Use proper collider type for GLB models - Trimesh collider can be added here if needed for complex shapes, but might be performance heavy */}
                <primitive object={gltf.scene} dispose={null} />
            </RigidBody>
        );
    } else {
        return <primitive object={gltf.scene} position={position} scale={scale} rotation={rotation} dispose={null} />;
    }
}

// --- Generic Model Component for other types (Sphere, Box, Plane) ---
function MeshGenericModel({ block }) {
    const mesh = useRef();
    const rigidBodyRef = useRef();

    // Extract position, scale, and rotation from block.object or use defaults
    let position = block.object?.position;
    if (position && typeof position === 'object' && !Array.isArray(position)) {
        position = [position.x || 0, position.y || 0, position.z || 0];
    } else {
        position = position || [0, 0, 0];
    }

    let scale = block.object?.scale;
    if (scale && typeof scale === 'object' && !Array.isArray(scale)) {
        scale = [scale.x || 1, scale.y || 1, scale.z || 1];
    } else {
        scale = scale || [1, 1, 1];
    }

    let rotation = block.object?.rotation;
    if (rotation && typeof rotation === 'object' && !Array.isArray(rotation)) {
        rotation = [rotation.x || 0, rotation.y || 0, rotation.z || 0];
    } else {
        rotation = rotation || [0, 0, 0];
    }

    const spinEnabled = block.animation?.spin?.enabled || false;
    const spinAxis = block.animation?.spin?.axis || 'y';
    const spinSpeed = block.animation?.spin?.speed || 0.01;

    const swingEnabled = block.animation?.swing?.enabled || false;
    let swingAmplitude = block.animation?.swing?.amplitude || 0.5;
    const swingSpeed = block.animation?.swing?.speed || 1;
    const swingAxis = block.animation?.swing?.axis || 'y';

    const positionAnimationEnabled = block.animation?.positionAnimation?.enabled || false;
    const positionAnimationLoop = block.animation?.positionAnimation?.loop || false;
    const positionAnimationDurationPerSegment = block.animation?.positionAnimation?.durationPerSegment || 2;
    const positionAnimationPositions = block.animation?.positionAnimation?.positions || [];

    const [currentPositionIndex, setCurrentPositionIndex] = useState(0);
    const [segmentStartTime, setSegmentStartTime] = useState(0);
    const [currentPosition, setCurrentPosition] = useState(position); // Use extracted position

    const textureURL = block.material?.type === 'custom' ? block.material?.color : null;
    // Always call the hook, but pass null if no texture is needed
    const texture = useLoader(THREE.TextureLoader, textureURL || 'https://storage.googleapis.com/download/storage/v1/b/fussydata/o/9d91f6eb0318678e693349df018033df.png?generation=1726630543074475&alt=media', (loader) => {
        if (!textureURL) return null; // Return null for placeholder texture
    });

    useEffect(() => {
        if (texture) {
            texture.encoding = THREE.SRGBColorSpace;
            texture.needsUpdate = true;
        }
    }, [texture]);

    // Physics properties
    const physicsEnabled = block.physics && block.physics.enabled;
    const isStatic = physicsEnabled && block.physics.mass === 0;

    // Handle animations that need to be managed outside physics
    useFrame((state, delta) => {
        // Skip animations if using physics-based movement
        if (physicsEnabled && !isStatic) return;

        if (spinEnabled && mesh.current) {
            if (spinAxis === 'x') {
                mesh.current.rotation.x += spinSpeed;
            } else if (spinAxis === 'y') {
                mesh.current.rotation.y += spinSpeed;
            } else if (spinAxis === 'z') {
                mesh.current.rotation.z += spinSpeed;
            }
        }

        if (swingEnabled && mesh.current) {
            const amplitudeRadians = swingAmplitude * Math.PI / 180;
            const swingAngle = Math.sin(state.clock.elapsedTime * swingSpeed) * amplitudeRadians;

            if (swingAxis === 'x') {
                mesh.current.rotation.x = swingAngle;
            } else if (swingAxis === 'y') {
                mesh.current.rotation.y = swingAngle;
            } else if (swingAxis === 'z') {
                mesh.current.rotation.z = swingAngle;
            }
        }

        if (positionAnimationEnabled && positionAnimationPositions.length > 1) {
            const positions = positionAnimationPositions;
            const duration = positionAnimationDurationPerSegment;
            const elapsedTimeInSegment = state.clock.elapsedTime - segmentStartTime;
            const progress = Math.min(elapsedTimeInSegment / duration, 1);

            const currentPos = positions[currentPositionIndex];
            const nextPositionIndex = (currentPositionIndex + 1) % positions.length;
            const nextPos = positions[nextPositionIndex];

            // Interpolate between current and next position
            const newPosition = [
                currentPos[0] + (nextPos[0] - currentPos[0]) * progress,
                currentPos[1] + (nextPos[1] - currentPos[1]) * progress,
                currentPos[2] + (nextPos[2] - currentPos[2]) * progress
            ];

            setCurrentPosition(newPosition);

            if (mesh.current && !physicsEnabled) {
                mesh.current.position.set(newPosition[0], newPosition[1], newPosition[2]);
            }

            if (rigidBodyRef.current && physicsEnabled) {
                rigidBodyRef.current.setTranslation(
                    { x: newPosition[0], y: newPosition[1], z: newPosition[2] },
                    true
                );
            }

            if (progress === 1) {
                if (positionAnimationLoop) {
                    setCurrentPositionIndex(nextPositionIndex);
                } else if (currentPositionIndex < positions.length - 1) {
                    setCurrentPositionIndex(nextPositionIndex);
                }
                setSegmentStartTime(state.clock.elapsedTime);
            }
        }
    });

    const materialProps = {
        roughness: block.material?.roughness !== undefined ? block.material.roughness : 0.5,
        metalness: 0.0, // Force metalness to 0
        emissive: block.material?.emissive || 'black',
        emissiveIntensity: block.material?.emissiveIntensity || 0,
        userData: block.material?.userData,
        encoding: THREE.SRGBColorSpace,
    };

    // Set color first, then potentially override with texture if textureURL exists
    if (block.material?.color) {
        materialProps.color = block.material.color;
    } else {
        materialProps.color = 'white'; // Default color if no color is specified
    }

    if (textureURL && texture) {
        materialProps.map = texture;
        delete materialProps.color; // Remove color if texture is used, to avoid potential conflicts
    }


    const meshContent = (
        <mesh
            ref={mesh}
            visible={block.visible !== undefined ? block.visible : true}
            castShadow={block.castShadow !== undefined ? block.castShadow : true}
            receiveShadow={block.receiveShadow !== undefined ? block.receiveShadow : true}
        >
            {renderGeometry(block.geometry)}
            <meshStandardMaterial {...materialProps} />
        </mesh>
    );

    const mass = physicsEnabled ? block.physics.mass : block.physics?.mass !== undefined ? block.physics.mass : 1; // default mass 1 if physics enabled but mass is undefined
    const restitution = physicsEnabled ? block.physics.restitution : 0.2;
    const friction = physicsEnabled ? block.physics.friction : 0.5;

    if (physicsEnabled) {
        return (
            <RigidBody
                ref={rigidBodyRef}
                position={currentPosition}
                scale={scale}
                rotation={rotation}
                type={isStatic ? "fixed" : "dynamic"}
                mass={mass}
                restitution={restitution}
                friction={friction}
                linearDamping={block.physics.linearDamping || 0.01}
                angularDamping={block.physics.angularDamping || 0.01}
            >
                {/* Add the appropriate collider based on geometry type */}
                {block.geometry?.type === 'sphereGeometry' && (
                    <BallCollider args={[block.geometry.args[0]]} />
                )}
                {block.geometry?.type === 'boxGeometry' && (
                    <CuboidCollider args={[
                        block.geometry.args[0] / 2,
                        block.geometry.args[1] / 2,
                        block.geometry.args[2] / 2
                    ]} />
                )}
                {block.geometry?.type === 'cylinderGeometry' && (
                    <CylinderCollider args={[
                        block.geometry.args[1] / 2,
                        block.geometry.args[0]
                    ]} />
                )}
                {block.geometry?.type === 'planeGeometry' && (
                    <CuboidCollider args={[
                        block.geometry.args[0] / 2,
                        0.01,
                        block.geometry.args[1] / 2
                    ]} />
                )}
                {/* For other geometry types, default to basic cuboid */}
                {!['sphereGeometry', 'boxGeometry', 'cylinderGeometry', 'planeGeometry'].includes(block.geometry?.type) && (
                    <CuboidCollider args={[0.5, 0.5, 0.5]} />
                )}

                {meshContent}
            </RigidBody>
        );
    } else {
        return React.cloneElement(meshContent, { position: currentPosition, scale: scale, rotation: rotation });
    }
}

// --- Ground Component ---
function GroundModel({ block }) {
    // This is specifically for ground planes
    const size = Array.isArray(block.geometry?.args) ?
        [block.geometry.args[0] || 50, 0.1, block.geometry.args[1] || 50] :
        [50, 0.1, 50];

    // Extract position, scale, and rotation from block.object or use defaults
    let position = block.object?.position;
    if (position && typeof position === 'object' && !Array.isArray(position)) {
        position = [position.x || 0, position.y || 0, position.z || 0];
    } else {
        position = position || [0, 0, 0];
    }

    let rotation = block.object?.rotation;
    if (rotation && typeof rotation === 'object' && !Array.isArray(rotation)) {
        rotation = [rotation.x || 0, rotation.y || 0, rotation.z || 0];
    } else {
        rotation = rotation || [0, 0, 0];
    }


    const textureURL = block.material?.type === 'custom' ? block.material?.color : null;
    const texture = useLoader(THREE.TextureLoader, textureURL || '/placeholder.png', (loader) => {
        if (!textureURL) return null;
    });

    useEffect(() => {
        if (texture) {
            texture.encoding = THREE.SRGBColorSpace;
            texture.needsUpdate = true;
        }
    }, [texture]);

    const materialProps = {
        roughness: block.material?.roughness !== undefined ? block.material.roughness : 0.5,
        metalness: 0.0, // Force metalness to 0
    };

    if (textureURL && texture) {
        materialProps.map = texture;
    } else {
        materialProps.color = block.material?.color || 'white';
    }

    return (
        <RigidBody type="fixed" position={position} rotation={rotation}>
            <mesh receiveShadow>
                <boxGeometry args={size} />
                <meshStandardMaterial {...materialProps} />
            </mesh>
            <CuboidCollider args={[size[0]/2, size[1]/2, size[2]/2]} />
        </RigidBody>
    );
}

// --- Models Component to decide which GenericModel to render ---
function Models({ blocks }) {
    console.log("Models component received blocks:", blocks); // ADDED: Log received blocks

    if (!blocks || !Array.isArray(blocks)) return null;

    const modelBlocks = blocks.filter(block => block.flow === 'model');
    const glbModelBlocks = modelBlocks.filter(block => block.type === 'glbModel'); // Filter for glbModel blocks

    console.log("Number of glbModel blocks found:", glbModelBlocks.length); // ADDED: Log number of GLB models

    const groundBlock = blocks.find(block =>
        block.tags && block.tags.includes('ground') && block.type === 'plane'
    );

    return (
        <>
            {/* Add a ground collider if there's a designated ground block */}
            {groundBlock && <GroundModel block={groundBlock} />}

            {/* Or add a default ground if none exists */}
            {!groundBlock && (
                <RigidBody type="fixed" position={[0, -1, 0]}>
                    <CuboidCollider args={[50, 0.1, 50]} />
                    <mesh receiveShadow position={[0, 0, 0]}>
                        <boxGeometry args={[100, 0.2, 100]} />
                        <meshStandardMaterial color="#555555" metalness={0} />
                    </mesh>
                </RigidBody>
            )}

            {/* Render all model blocks */}
            {modelBlocks.map((block, index) => {
                switch (block.type) {
                    case 'glbModel':
                        return <GLBGenericModel key={index} block={block} />;
                    default:
                        return <MeshGenericModel key={index} block={block} />;
                }
            })}
        </>
    );
}

export default Models;