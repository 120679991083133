import React, { useRef } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';

function Background({ block }) {
  const { scene } = useThree();
  const gradientRef = useRef();

  React.useEffect(() => {
    if (!block) return;

    // Clear any existing background
    if (scene.background) {
      if (scene.background.isTexture) {
        scene.background.dispose();
      }
      scene.background = null;
    }

    if (block.type === 'gradient' && Array.isArray(block.colors) && Array.isArray(block.stops)) {
      // Create gradient texture
      const size = block.size || 1024;
      const canvas = document.createElement('canvas');
      canvas.width = size;
      canvas.height = size;
      const context = canvas.getContext('2d');

      // Create gradient
      const gradient = context.createLinearGradient(0, 0, 0, size);
      
      // Add color stops
      block.colors.forEach((color, index) => {
        const stop = block.stops[index] !== undefined ? block.stops[index] : index / (block.colors.length - 1);
        gradient.addColorStop(stop, color);
      });

      // Fill with gradient
      context.fillStyle = gradient;
      context.fillRect(0, 0, size, size);

      // Create texture from canvas
      const texture = new THREE.CanvasTexture(canvas);
      texture.needsUpdate = true;
      
      // Set scene background
      scene.background = texture;
      
      return () => {
        texture.dispose();
        canvas.remove();
      };
    } else if (block.background) {
      // Set solid color background
      scene.background = new THREE.Color(block.background);
    }
  }, [block, scene]);

  return null;
}

export default Background;