// NPCs.js
import React from 'react';
import NPC from './NPC';

function NPCs({ blockList }) {
  const npcBlocks = blockList ? blockList.filter(block => block.flow === 'npc') : [];

  console.log(`NPCs component: Loading ${npcBlocks.length} NPCs.`); // Console log NPC count

  return (
    <>
      {npcBlocks.map(block => (
        <NPC key={block.name} block={block} />
      ))}
    </>
  );
}

export default NPCs;