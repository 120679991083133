import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { RigidBody, CapsuleCollider } from '@react-three/rapier';

function Boat({ block }) {
  const gltf = useGLTF(block.geometry.url);
  const boatRef = useRef();
  const rigidBodyRef = useRef();
  const animationMixerRef = useRef();
  const animationsMapRef = useRef({});
  const [currentAnimation, setCurrentAnimation] = useState('Idle');
  const moveDirection = useRef(new THREE.Vector3());
  const speed = useRef(0);
  const { camera } = useThree();
  const currentPositionRef = useRef({ x: 0, y: 0, z: 0, rotation: 0 });

  const setupAnimations = useCallback(() => {
    if (block.animation && block.animation.animations && gltf.animations) {
      animationMixerRef.current = new THREE.AnimationMixer(gltf.scene);
      block.animation.animations.forEach((anim) => {
        const clip = THREE.AnimationClip.findByName(gltf.animations, anim.clipName);
        if (clip) {
          animationsMapRef.current[anim.name] = animationMixerRef.current.clipAction(clip);
          if (anim.playOnLoad) {
            animationsMapRef.current[anim.name].play();
            setCurrentAnimation(anim.name);
          }
        } else {
          console.warn(`Animation clip "${anim.clipName}" not found in GLTF for animation "${anim.name}".`);
        }
      });
      if (
        block.animation.defaultAnimation &&
        animationsMapRef.current[block.animation.defaultAnimation] &&
        !block.animation.animations.find((anim) => anim.name === block.animation.defaultAnimation)?.playOnLoad
      ) {
        animationsMapRef.current[block.animation.defaultAnimation].play();
        setCurrentAnimation(block.animation.defaultAnimation);
      }
    } else {
      console.log('No animations available for boat:', block.name);
    }
  }, [gltf, block.animation]);

  useEffect(() => {
    if (gltf) {
      console.log('GLTF model loaded successfully for boat:', block.name);
      setupAnimations();

      gltf.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.castShadow = block.castShadow;
          child.receiveShadow = block.receiveShadow;
        }
      });

      boatRef.current.add(gltf.scene);
      gltf.scene.position.set(0, 0, 0); // Mesh offset relative to RigidBody
      boatRef.current.position.set(0, 0, 0);
      boatRef.current.scale.set(block.scale, block.scale, block.scale);

      if (block.position) {
        // Adjust y to -1 as per requirement, keeping x and z from block.position
        const [x, , z] = block.position;
        currentPositionRef.current = { x, y:-1, z, rotation: 0 };
      }
    } else {
      console.error('Failed to load GLTF model for boat:', block.name, block.geometry.url);
    }
  }, [gltf, block, setupAnimations]);

  const playAnimation = useCallback(
    (name) => {
      if (currentAnimation !== name && animationsMapRef.current[name]) {
        const fadeDuration = 0.2;
        animationsMapRef.current[currentAnimation]?.fadeOut(fadeDuration);
        animationsMapRef.current[name]?.reset().fadeIn(fadeDuration).play();
        setCurrentAnimation(name);
      }
    },
    [currentAnimation]
  );

  useFrame((state, delta) => {
    if (animationMixerRef.current) {
      animationMixerRef.current.update(delta);
    }

    // Add rocking motion to simulate water
    const time = state.clock.getElapsedTime();
    const rockAngleX = Math.sin(time * 2) * 0.05; // Small rocking on x-axis
    const rockAngleZ = Math.cos(time * 1.5) * 0.03; // Small rocking on z-axis
    boatRef.current.rotation.x = rockAngleX;
    boatRef.current.rotation.z = rockAngleZ;

    const currentSpeed = speed.current;
    if (currentSpeed > 0 && rigidBodyRef.current) {
      const direction = new THREE.Vector3();
      const forward = new THREE.Vector3();
      camera.getWorldDirection(forward);
      forward.y = 0;
      forward.normalize();
      const right = new THREE.Vector3().crossVectors(camera.up, forward).normalize();

      direction.addScaledVector(forward, moveDirection.current.z);
      direction.addScaledVector(right, moveDirection.current.x);

      if (direction.length() > 0) {
        direction.normalize();
        const currentVel = rigidBodyRef.current.linvel();
        rigidBodyRef.current.setLinvel(
          { x: direction.x * currentSpeed, y: currentVel.y, z: direction.z * currentSpeed },
          true
        );
        const targetRotation = Math.atan2(direction.x, direction.z);
        boatRef.current.rotation.y = targetRotation;
        currentPositionRef.current.rotation = targetRotation;
      }

      // Play 'Run' animation if available, otherwise just move
      if (animationsMapRef.current['Run']) {
        playAnimation('Run');
      }
    } else if (animationsMapRef.current['Idle']) {
      playAnimation('Idle');
    }

    if (rigidBodyRef.current) {
      const position = rigidBodyRef.current.translation();
      currentPositionRef.current.x = position.x;
      currentPositionRef.current.y = position.y;
      currentPositionRef.current.z = position.z;

      window.dispatchEvent(
        new CustomEvent('playerPositionUpdate', {
          detail: {
            position: currentPositionRef.current,
          },
        })
      );
    }
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.code) {
        case 'KeyW':
          moveDirection.current.z = 1;
          speed.current = block.behavior?.behaviorData?.walkSpeed || 5;
          break;
        case 'KeyS':
          moveDirection.current.z = -1;
          speed.current = block.behavior?.behaviorData?.walkSpeed || 5;
          break;
        case 'KeyA':
          moveDirection.current.x = -1;
          speed.current = block.behavior?.behaviorData?.walkSpeed || 5;
          break;
        case 'KeyD':
          moveDirection.current.x = 1;
          speed.current = block.behavior?.behaviorData?.walkSpeed || 5;
          break;
        default:
          return;
      }
    };

    const handleKeyUp = (event) => {
      switch (event.code) {
        case 'KeyW':
          if (moveDirection.current.z > 0) moveDirection.current.z = 0;
          break;
        case 'KeyS':
          if (moveDirection.current.z < 0) moveDirection.current.z = 0;
          break;
        case 'KeyA':
          if (moveDirection.current.x < 0) moveDirection.current.x = 0;
          break;
        case 'KeyD':
          if (moveDirection.current.x > 0) moveDirection.current.x = 0;
          break;
        default:
          return;
      }
      if (moveDirection.current.z === 0 && moveDirection.current.x === 0) speed.current = 0;
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [block.behavior?.behaviorData?.walkSpeed]);

  // Initial position with y = -1 to place boat slightly in water
  const initialPosition = block.position
    ? [block.position[0], -1, block.position[2]]
    : [0, -1, 0];

  return (
    <RigidBody
      ref={rigidBodyRef}
      position={initialPosition}
      type="dynamic"
      mass={block.physics.mass || 2}
      restitution={block.physics.restitution || 0.2}
      friction={block.physics.friction || 0.8}
      linearDamping={1.5}
      angularDamping={1.0}
      lockRotations={block.physics.fixedRotation || true}
      colliders={false}
      enabledTranslations={[true, true, true]}
      gravityScale={1.0}
      name={block.name}
    >
      <group ref={boatRef} visible={block.visible}>
        {block.physics.shapeType === 'capsule' && (
          <CapsuleCollider
            args={block.physics.shapeArgs || [0.6, 0.4]}
            position={[0, (block.physics.shapeArgs?.[1] || 0.4) / 2, 0]}
          />
        )}
       
      </group>
    </RigidBody>
  );
}

export default Boat;