// NPC.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { RigidBody, CapsuleCollider } from '@react-three/rapier';

function NPC({ block }) {
  const gltf = useGLTF(block.geometry.url);
  const npcRef = useRef();
  const rigidBodyRef = useRef();
  const animationMixerRef = useRef();
  const animationsMapRef = useRef({});
  const [currentAnimation, setCurrentAnimation] = useState('Idle');
  const moveDirection = useRef(new THREE.Vector3());
  const speed = useRef(0);
  const canJump = useRef(true);
  const jumpForce = 5;
  const currentPositionRef = useRef({x: 0, y: 0, z: 0, rotation: 0});
  const initialPositionSet = useRef(false);

  const positionAnimationPositions = useRef(block.animation?.positionAnimation?.positions || []);
  const positionAnimationLoop = useRef(block.animation?.positionAnimation?.loop || false);
  const positionAnimationDurationPerSegment = useRef(block.animation?.positionAnimation?.durationPerSegment || 2);
  const currentPositionIndex = useRef(0);
  const segmentStartTime = useRef(0);
  const isMoving = useRef(false);
  const targetPosition = useRef(new THREE.Vector3()); // Ref to store the current target position

  const setupAnimations = useCallback(() => {
    if (gltf && block.animation && block.animation.animations) {
      animationMixerRef.current = new THREE.AnimationMixer(gltf.scene);
      block.animation.animations.forEach(anim => {
        if (gltf.animations) {
          const clip = THREE.AnimationClip.findByName(gltf.animations, anim.clipName);
          if (clip) {
            animationsMapRef.current[anim.name] = animationMixerRef.current.clipAction(clip);
            if (anim.playOnLoad) {
              animationsMapRef.current[anim.name].play();
              setCurrentAnimation(anim.name);
            }
          } else {
            console.warn(`Animation clip "${anim.clipName}" not found in GLTF for animation "${anim.name}".`);
          }
        } else {
          console.warn(`No animations found in GLTF for block "${block.name}".`);
        }
      });

      if (block.animation.defaultAnimation && animationsMapRef.current[block.animation.defaultAnimation] && !block.animation.animations.find(anim => anim.name === block.animation.defaultAnimation)?.playOnLoad) {
        animationsMapRef.current[block.animation.defaultAnimation].play();
        setCurrentAnimation(block.animation.defaultAnimation);
      }
    }
  }, [gltf, block.animation]);

  useEffect(() => {
    if (gltf) {
      setupAnimations();

      gltf.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.castShadow = block.castShadow;
          child.receiveShadow = block.receiveShadow;
        }
      });

      npcRef.current.add(gltf.scene);

      const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
      const modelHeight = boundingBox.getSize(new THREE.Vector3()).y;

      gltf.scene.rotation.set(0, Math.PI, 0);
      gltf.scene.position.set(0, 1, 0);

      npcRef.current.position.set(0, 0, 0);
      npcRef.current.scale.set(block.scale, block.scale, block.scale);

      if (block.position) {
        currentPositionRef.current = {x: block.position[0], y: block.position[1], z: block.position[2], rotation: 0};
      }

      console.log(`NPC "${block.name}" starting position:`, currentPositionRef.current);
    }
  }, [gltf, block, setupAnimations]);

  const playAnimation = useCallback((name) => {
    if (currentAnimation !== name && animationsMapRef.current[name]) {
      const fadeDuration = 0.2;
      animationsMapRef.current[currentAnimation]?.fadeOut(fadeDuration);
      animationsMapRef.current[name]?.reset().fadeIn(fadeDuration).play();
      setCurrentAnimation(name);
    }
  }, [currentAnimation]);

  useFrame((state, delta) => {
    if (animationMixerRef.current) {
      animationMixerRef.current.update(delta);
    }

    if (block.animation?.positionAnimation?.enabled && positionAnimationPositions.current.length > 1) {
        const positions = positionAnimationPositions.current;
        const duration = positionAnimationDurationPerSegment.current;
        const elapsedTimeInSegment = state.clock.elapsedTime - segmentStartTime.current;
        const progress = Math.min(elapsedTimeInSegment / duration, 1);

        const currentPos = positions[currentPositionIndex.current];
        const startPosition = initialPositionSet.current ? currentPos : block.position;
        const nextPositionIndex = (currentPositionIndex.current + 1) % positions.length;
        const nextPos = positions[nextPositionIndex];

        // Set target position for orientation calculation
        targetPosition.current.set(nextPos[0], nextPos[1], nextPos[2]);

        const newPosition = new THREE.Vector3();
        newPosition.x = startPosition[0] + (nextPos[0] - startPosition[0]) * progress;
        newPosition.y = startPosition[1] + (nextPos[1] - startPosition[1]) * progress;
        newPosition.z = startPosition[2] + (nextPos[2] - startPosition[2]) * progress;

        if (rigidBodyRef.current) {
            rigidBodyRef.current.setTranslation(newPosition, true);
            isMoving.current = true;

            // NPC Orientation - Face towards target
            const direction = targetPosition.current.clone().sub(rigidBodyRef.current.translation());
            direction.y = 0; // Keep rotation horizontal
            if (direction.lengthSq() > 0.01) { // Avoid lookAt when NPC is very close to target
                direction.normalize();
                const lookAtQuaternion = new THREE.Quaternion().setFromRotationMatrix(
                    new THREE.Matrix4().lookAt(rigidBodyRef.current.translation(), targetPosition.current, new THREE.Vector3(0, 1, 0))
                );
                npcRef.current.quaternion.slerp(lookAtQuaternion, 0.1); // Smooth rotation
            }
        }

        if (progress === 1) {
            if (!initialPositionSet.current) {
                initialPositionSet.current = true;
            }
            currentPositionIndex.current = nextPositionIndex; // Move to the next position in array for next segment
            segmentStartTime.current = state.clock.elapsedTime;
        }
    } else {
        isMoving.current = false;
    }

    if (isMoving.current) {
        playAnimation('Run');
    } else {
      playAnimation('Idle');    }

    if (rigidBodyRef.current) {
      const position = rigidBodyRef.current.translation();
      currentPositionRef.current.x = position.x;
      currentPositionRef.current.y = position.y;
      currentPositionRef.current.z = position.z;

      window.dispatchEvent(new CustomEvent('npcPositionUpdate', {
        detail: {
          name: block.name,
          position: currentPositionRef.current
        }
      }));

      const vel = rigidBodyRef.current.linvel();
      if (Math.abs(vel.y) < 0.1) {
        canJump.current = true;
      }
    }
  });


  const initialPosition = block.position || [0, 1, 0];

  return (
    <RigidBody
      ref={rigidBodyRef}
      position={initialPosition}
      type="dynamic"
      mass={2}
      restitution={0.2}
      friction={0.8}
      linearDamping={1.5}
      angularDamping={1.0}
      lockRotations={true}
      colliders={false}
      enabledTranslations={[true, true, true]}
      gravityScale={1.0}
      onCollisionEnter={(e) => {
        if (e.normal.y > 0.5) {
          canJump.current = true;
        }
      }}
      name={block.name}
    >
      <group ref={npcRef} visible={block.visible}>
        <CapsuleCollider
          args={[0.6, 0.4]}
          position={[0, 0.9, 0]}
        />
      </group>
    </RigidBody>
  );
}

export default NPC;