// Airplane.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { RigidBody, CapsuleCollider, CuboidCollider } from '@react-three/rapier';

function Airplane({ block }) {
  const gltf = useGLTF(block.geometry.url);
  const airplaneRef = useRef();
  const airplaneRigidBodyRef = useRef();
  const animationMixerRef = useRef();
  const animationsMapRef = useRef({});
  const [currentAnimation, setCurrentAnimation] = useState('Idle');
  const moveDirection = useRef(new THREE.Vector3());
  const speed = useRef(0);
  const elevationSpeed = useRef(0);
  const { camera } = useThree();
  const currentPositionRef = useRef({x: 0, y: 0, z: 0, rotation: 0});

  const setupAnimations = useCallback(() => {
    if (gltf && block.animation && block.animation.animations) {
      animationMixerRef.current = new THREE.AnimationMixer(gltf.scene);
      block.animation.animations.forEach(anim => {
        if (gltf.animations) {
          const clip = THREE.AnimationClip.findByName(gltf.animations, anim.clipName);
          if (clip) {
            animationsMapRef.current[anim.name] = animationMixerRef.current.clipAction(clip);
            if (anim.playOnLoad) {
              animationsMapRef.current[anim.name].play();
              setCurrentAnimation(anim.name);
            }
          } else {
            console.warn(`Animation clip "${anim.clipName}" not found in GLTF for animation "${anim.name}".`);
          }
        } else {
          console.warn(`No animations found in GLTF for block "${block.name}".`);
        }
      });

      if (block.animation.defaultAnimation && animationsMapRef.current[block.animation.defaultAnimation] && !block.animation.animations.find(anim => anim.name === block.animation.defaultAnimation)?.playOnLoad) {
        animationsMapRef.current[block.animation.defaultAnimation].play();
        setCurrentAnimation(block.animation.defaultAnimation);
      }
    }
  }, [gltf, block.animation]);

  useEffect(() => {
    if (gltf) {
      setupAnimations();

      gltf.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.castShadow = block.castShadow;
          child.receiveShadow = block.receiveShadow;
        }
      });

      airplaneRef.current.add(gltf.scene);

      const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
      const modelHeight = boundingBox.getSize(new THREE.Vector3()).y;

      // Correctly rotate the airplane to face away from the camera initially
      gltf.scene.rotation.set(0, Math.PI, 0); // This will make it face away from the camera
      gltf.scene.position.set(0, 0, 0);

      airplaneRef.current.position.set(0, 0, 0);
      airplaneRef.current.scale.set(block.scale, block.scale, block.scale);

      console.log("Airplane Initial Position:", block.position);

      if (block.position) {
        currentPositionRef.current = {x: block.position[0], y: block.position[1], z: block.position[2], rotation: 0};
      }
    }
  }, [gltf, block, setupAnimations]);

  const playAnimation = useCallback((name) => {
    if (currentAnimation !== name && animationsMapRef.current[name]) {
      const fadeDuration = 0.2;
      animationsMapRef.current[currentAnimation]?.fadeOut(fadeDuration);
      animationsMapRef.current[name]?.reset().fadeIn(fadeDuration).play();
      setCurrentAnimation(name);
    }
  }, [currentAnimation]);

  useFrame((state, delta) => {
    if (animationMixerRef.current) {
      animationMixerRef.current.update(delta);
    }

    const flySpeed = block.behavior?.behaviorData?.flySpeed || 20;
    const currentSpeed = speed.current;
    const currentElevationSpeed = elevationSpeed.current;

    if ((currentSpeed !== 0 || currentElevationSpeed !== 0) && airplaneRigidBodyRef.current) {
      const direction = new THREE.Vector3();

      // Forward is +Z due to the PI rotation we applied
      const forward = new THREE.Vector3(0, 0, 1);
      forward.applyQuaternion(camera.quaternion);
      forward.y = 0;
      forward.normalize();

      const right = new THREE.Vector3(1, 0, 0);
      right.applyQuaternion(camera.quaternion);
      right.normalize();

      direction.addScaledVector(forward, moveDirection.current.z);
      direction.addScaledVector(right, moveDirection.current.x);

      if (direction.length() > 0) {
        direction.normalize();
      }

      // Apply velocity directly
      airplaneRigidBodyRef.current.setLinvel(
        {
          x: direction.x * currentSpeed,
          y: currentElevationSpeed,
          z: direction.z * currentSpeed
        },
        true
      );

      // Maintain horizontal orientation - stabilize the airplane
      const currentRot = airplaneRigidBodyRef.current.rotation();
      const currentEuler = new THREE.Euler().setFromQuaternion(
        new THREE.Quaternion(currentRot.x, currentRot.y, currentRot.z, currentRot.w)
      );

      // Apply a slight corrective torque to maintain horizontal flight unless actively changing elevation
      if (Math.abs(currentEuler.x) > 0.05 || Math.abs(currentEuler.z) > 0.05) {
        airplaneRigidBodyRef.current.applyTorqueImpulse(
          {
            x: -currentEuler.x * 5, // Correct roll
            y: 0,
            z: -currentEuler.z * 5  // Correct pitch
          },
          true
        );
      }

      if (currentSpeed > 0 || currentElevationSpeed !== 0) {
          playAnimation('Run');
      } else {
          playAnimation('Idle');
      }

      if (direction.lengthSq() > 0) {
        const targetRotation = Math.atan2(direction.x, direction.z);
        airplaneRef.current.rotation.y = targetRotation;
        currentPositionRef.current.rotation = targetRotation;
      }
    } else {
      playAnimation('Idle');

      // Apply stabilization even when not moving
      if (airplaneRigidBodyRef.current) {
        const currentRot = airplaneRigidBodyRef.current.rotation();
        const currentEuler = new THREE.Euler().setFromQuaternion(
          new THREE.Quaternion(currentRot.x, currentRot.y, currentRot.z, currentRot.w)
        );

        if (Math.abs(currentEuler.x) > 0.05 || Math.abs(currentEuler.z) > 0.05) {
          airplaneRigidBodyRef.current.applyTorqueImpulse(
            {
              x: -currentEuler.x * 5,
              y: 0,
              z: -currentEuler.z * 5
            },
            true
          );
        }
      }
    }

    if (airplaneRigidBodyRef.current) {
      const position = airplaneRigidBodyRef.current.translation();
      currentPositionRef.current.x = position.x;
      currentPositionRef.current.y = position.y;
      currentPositionRef.current.z = position.z;

      window.dispatchEvent(new CustomEvent('airplanePositionUpdate', {
        detail: {
          position: currentPositionRef.current,
          quaternion: airplaneRigidBodyRef.current.rotation()
        }
      }));
    }
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.code) {
        case 'KeyQ': elevationSpeed.current = block.behavior?.behaviorData?.flyElevationSpeed || 10; break; // Ascend
        case 'KeyE': elevationSpeed.current = - (block.behavior?.behaviorData?.flyElevationSpeed || 10); break; // Descend
        case 'KeyW': moveDirection.current.z = 1; speed.current = block.behavior?.behaviorData?.flySpeed || 20; break; // Forward
        case 'KeyS': moveDirection.current.z = -1; speed.current = block.behavior?.behaviorData?.flySpeed || 20; break; // Backward
        case 'KeyA': moveDirection.current.x = -1; speed.current = block.behavior?.behaviorData?.flySpeed || 20; break; // Left
        case 'KeyD': moveDirection.current.x = 1; speed.current = block.behavior?.behaviorData?.flySpeed || 20; break; // Right
        default: return;
      }
    };

    const handleKeyUp = (event) => {
      switch (event.code) {
        case 'KeyQ': if (elevationSpeed.current > 0) elevationSpeed.current = 0; break;
        case 'KeyE': if (elevationSpeed.current < 0) elevationSpeed.current = 0; break;
        case 'KeyW': if (moveDirection.current.z > 0) moveDirection.current.z = 0; break;
        case 'KeyS': if (moveDirection.current.z < 0) moveDirection.current.z = 0; break;
        case 'KeyA': if (moveDirection.current.x < 0) moveDirection.current.x = 0; break;
        case 'KeyD': if (moveDirection.current.x > 0) moveDirection.current.x = 0; break;
        default: return;
      }
      if (moveDirection.current.z === 0 && moveDirection.current.x === 0) speed.current = 0;
      if (elevationSpeed.current === 0 && (event.code === 'KeyQ' || event.code === 'KeyE')) elevationSpeed.current = 0;
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [block.behavior?.behaviorData?.flySpeed, block.behavior?.behaviorData?.flyElevationSpeed]);

  const initialPosition = block.position || [0, 1, 0];

  return (
    <RigidBody
      ref={airplaneRigidBodyRef}
      position={initialPosition}
      type="dynamic"
      mass={1}
      restitution={0.0}
      friction={0.2}
      linearDamping={0.1}
      angularDamping={0.5}
      lockRotations={false}
      colliders={false}
      enabledTranslations={[true, true, true]}
      gravityScale={0.0} // Zero gravity for more stable flight
      name={block.name}
    >
      <group ref={airplaneRef} visible={block.visible}>
        <CuboidCollider
          args={[1, 0.2, 2]}
          position={[0, 0.2, 0]}
        />
      </group>
    </RigidBody>
  );
}

export default Airplane;