import React, { useRef, useEffect, useState, Suspense, useCallback } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { PointerLockControls, OrbitControls, Sky } from '@react-three/drei';
import * as THREE from 'three';
import './Sim.css';
import { useParams } from 'react-router-dom';
import Models from './Models';
import Ground from './Ground';
import Light from './Light';
import SFX from './SFX';
import Characters from './Characters';
import NPCs from './NPCs';
import GUI from './GUI';
import { Physics, RigidBody, CuboidCollider } from '@react-three/rapier';
import Skybox from './Skybox';
import Background from './Background';
import Ocean from './Ocean';

function Sim() {
  const { id } = useParams();
  const [blockList, setBlockList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`https://api.neocu.com/api/block?data_id=${id}&status=active`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        if (json.isSuccess) {
          setBlockList(json.blockList);
        } else {
          setError('Failed to fetch block data from API.');
        }
      } catch (e) {
        setError('Error fetching data: ' + e.message);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
      console.log('Fetching data for id:', id);
    } else {
      setLoading(false);
    }
  }, [id]);


  if (loading) {
    return <div className="sim-container">Loading simulation data...</div>;
  }

  if (error) {
    return <div className="sim-container">Error: {error}</div>;
  }

  const cameraBlock = blockList && blockList.find((block) => block.flow === 'camera');
  // Check for position in cameraBlock.object, then cameraBlock.position, then default
  const initialCameraPosition = cameraBlock?.object?.position || cameraBlock?.position || [0, 5, 15];

  const skyBlock = blockList && blockList.find((block) => block.flow === 'skybox' || block.flow === 'sky');
  const backgroundBlock = blockList && blockList.find((block) => block.flow === 'background');

  const shouldRenderBackground = !skyBlock && backgroundBlock;

  const simContent = (
    <>
      <GUI blockList={blockList} />
      <Canvas camera={{ position: initialCameraPosition }}>
        <Suspense fallback={null}>
          <Physics gravity={[0, -9.81, 0]} interpolation={true} debug={false}>
            <CameraSettings blockList={blockList} />
            {blockList && blockList.some((block) => block.flow === 'ocean') ? (
              <Ocean blocks={blockList.filter((block) => block.flow === 'ocean')} />
            ) : (
              <Ground blocks={blockList && blockList.filter((block) => block.flow === 'ground')} />
            )}
            <Models blocks={blockList && blockList.filter((block) => block.flow === 'model')} />
            <Characters blockList={blockList} />
            <NPCs blockList={blockList} />
            <Light blockList={blockList} />
            <SFX blockList={blockList} />
            {skyBlock && <Skybox block={skyBlock} />}
            {shouldRenderBackground && <Background block={backgroundBlock} />}
          </Physics>
        </Suspense>
      </Canvas>
    </>
  );

  return (
    <div className="sim-container">
        {simContent}
    </div>
  );
}

function CameraSettings({ blockList }) {

  const { camera, gl } = useThree();
  const controlsRef = useRef();
  const moveForward = useRef(false);
  const moveBackward = useRef(false);
  const moveLeft = useRef(false);
  const moveRight = useRef(false);
  const moveSpeed = useRef(100);
  const jumpForce = useRef(5);
  const canJump = useRef(true);
  const cameraOffsetY = 1.6;

  // Third-person camera rotation controls for player or boat
  const rotateLeft = useRef(false);
  const rotateRight = useRef(false);
  const rotateUp = useRef(false);
  const rotateDown = useRef(false);
  const cameraYaw = useRef(0);
  const cameraPitch = useRef(0);

  // Airplane camera rotation controls
  const rotateLeftAirplane = useRef(false);
  const rotateRightAirplane = useRef(false);
  const rotateUpAirplane = useRef(false);
  const rotateDownAirplane = useRef(false);
  const cameraYawAirplane = useRef(0);
  const cameraPitchAirplane = useRef(0);

  const velocity = useRef(new THREE.Vector3());
  const playerRef = useRef();
  const rigidBodyRef = useRef();
  const playerPositionRef = useRef({ x: 0, y: 0, z: 0, rotation: 0 });

  const airplaneRef = useRef();
  const airplaneRigidBodyRef = useRef();
  const airplanePositionRef = useRef({ x: 0, y: 0, z: 0, rotation: 0 });
  const airplaneQuaternionRef = useRef(new THREE.Quaternion());

  const cameraOffsetDistance = useRef(12);
  const cameraLerpFactor = useRef(0.05);

  const isLocked = useRef(false);
  const onGround = useRef(true);

  const initialPosition =
    blockList &&
    blockList.find((block) => block.flow === 'camera')?.object?.position &&
    Array.isArray(blockList.find((block) => block.flow === 'camera')?.object?.position) &&
    blockList.find((block) => block.flow === 'camera').object.position.length === 3
      ? blockList.find((block) => block.flow === 'camera').object.position
      : [0, 5, 15];

  const playerInitialPosition = blockList && blockList.find((block) => block.flow === 'player')?.position;
  const airplaneInitialPosition = blockList && blockList.find((block) => block.flow === 'airplane')?.position;
  const boatInitialPosition = blockList && blockList.find((block) => block.flow === 'boat')?.position;

  useEffect(() => {
    if (playerInitialPosition && rigidBodyRef.current) {
      rigidBodyRef.current.setTranslation(
        {
          x: playerInitialPosition[0],
          y: playerInitialPosition[1],
          z: playerInitialPosition[2],
        },
        true
      );
    }
    if (airplaneInitialPosition && airplaneRigidBodyRef.current) {
      airplaneRigidBodyRef.current.setTranslation(
        {
          x: airplaneInitialPosition[0],
          y: airplaneInitialPosition[1],
          z: airplaneInitialPosition[2],
        },
        true
      );
    }
    // Boat position is set in Boat.js
  }, [playerInitialPosition, airplaneInitialPosition]);

  const [position, setPosition] = useState(initialPosition);

  useEffect(() => {
    const handlePlayerPositionUpdate = (event) => {
      playerPositionRef.current = event.detail.position;
    };
    const handleAirplanePositionUpdate = (event) => {
      airplanePositionRef.current = event.detail.position;
      airplaneQuaternionRef.current = event.detail.quaternion;
    };

    window.addEventListener('playerPositionUpdate', handlePlayerPositionUpdate);
    window.addEventListener('airplanePositionUpdate', handleAirplanePositionUpdate);

    return () => {
      window.removeEventListener('playerPositionUpdate', handlePlayerPositionUpdate);
      window.removeEventListener('airplanePositionUpdate', handleAirplanePositionUpdate);
    };
  }, []);

  useEffect(() => {
    if (!blockList) return;
    const cameraBlock = blockList.find((block) => block.flow === 'camera');
    const airplaneBlockExists = blockList.some((block) => block.flow === 'airplane');
    const controlledBlockExists = blockList.some(
      (block) => block.flow === 'player' || block.flow === 'boat'
    );
    if (cameraBlock?.object?.type === 'thirdPerson' && !airplaneBlockExists && controlledBlockExists) {
      const onKeyDown = (event) => {
        switch (event.code) {
          case 'ArrowLeft':
            rotateLeft.current = true;
            break;
          case 'ArrowRight':
            rotateRight.current = true;
            break;
          case 'ArrowUp':
            rotateUp.current = true;
            break;
          case 'ArrowDown':
            rotateDown.current = true;
            break;
        }
      };

      const onKeyUp = (event) => {
        switch (event.code) {
          case 'ArrowLeft':
            rotateLeft.current = false;
            break;
          case 'ArrowRight':
            rotateRight.current = false;
            break;
          case 'ArrowUp':
            rotateUp.current = false;
            break;
          case 'ArrowDown':
            rotateDown.current = false;
            break;
        }
      };

      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);

      return () => {
        document.removeEventListener('keydown', onKeyDown);
        document.removeEventListener('keyup', onKeyUp);
      };
    }
  }, [blockList]);

  useEffect(() => {
    if (!blockList) return;
    const airplaneBlockExists = blockList.some((block) => block.flow === 'airplane');
    if (airplaneBlockExists) {
      const onKeyDown = (event) => {
        switch (event.code) {
          case 'ArrowLeft':
            rotateLeftAirplane.current = true;
            break;
          case 'ArrowRight':
            rotateRightAirplane.current = true;
            break;
          case 'ArrowUp':
            rotateUpAirplane.current = true;
            break;
          case 'ArrowDown':
            rotateDownAirplane.current = true;
            break;
        }
      };

      const onKeyUp = (event) => {
        switch (event.code) {
          case 'ArrowLeft':
            rotateLeftAirplane.current = false;
            break;
          case 'ArrowRight':
            rotateRightAirplane.current = false;
            break;
          case 'ArrowUp':
            rotateUpAirplane.current = false;
            break;
          case 'ArrowDown':
            rotateDownAirplane.current = false;
            break;
        }
      };

      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);

      return () => {
        document.removeEventListener('keydown', onKeyDown);
        document.removeEventListener('keyup', onKeyUp);
      };
    }
  }, [blockList]);

  useEffect(() => {
    if (!blockList) return;
    const cameraBlock = blockList.find((block) => block.flow === 'camera');
    const airplaneBlockExists = blockList.some((block) => block.flow === 'airplane');
    if (cameraBlock?.object?.type === 'firstPerson' && !airplaneBlockExists) {
      const isFirstPerson = cameraBlock?.object?.type === 'firstPerson';
      const cameraPosition = cameraBlock?.object?.position; // Check object.position

      if (!isFirstPerson || !cameraPosition || !Array.isArray(cameraPosition) || cameraPosition.length !== 3)
        return;

      setPosition(cameraPosition);

      const onKeyDown = (event) => {
        if (!isLocked.current) return;

        switch (event.code) {
          case 'Space':
            if (onGround.current && rigidBodyRef.current) {
              rigidBodyRef.current.applyImpulse({ x: 0, y: jumpForce.current, z: 0 }, true);
              onGround.current = false;
            }
            break;
          case 'KeyW':
          case 'ArrowUp':
            moveForward.current = true;
            break;
          case 'KeyS':
          case 'ArrowDown':
            moveBackward.current = true;
            break;
          case 'KeyA':
          case 'ArrowLeft':
            moveLeft.current = true;
            break;
          case 'KeyD':
          case 'ArrowRight':
            moveRight.current = true;
            break;
        }
      };

      const onKeyUp = (event) => {
        switch (event.code) {
          case 'KeyW':
          case 'ArrowUp':
            moveForward.current = false;
            break;
          case 'KeyS':
          case 'ArrowDown':
            moveBackward.current = false;
            break;
          case 'KeyA':
          case 'ArrowLeft':
            moveLeft.current = false;
            break;
          case 'KeyD':
          case 'ArrowRight':
            moveRight.current = false;
            break;
        }
      };

      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);

      return () => {
        document.removeEventListener('keydown', onKeyDown);
        document.removeEventListener('keyup', onKeyUp);
      };
    }
  }, [blockList]);

  useFrame((state, delta) => {
    if (!blockList) return;
    const cameraBlock = blockList.find((block) => block.flow === 'camera');
    const airplaneBlockExists = blockList.some((block) => block.flow === 'airplane');
    const isFirstPerson = cameraBlock?.object?.type === 'firstPerson';
    const isThirdPerson = cameraBlock?.object?.type === 'thirdPerson';

    if (airplaneBlockExists) {
      if (!airplanePositionRef.current) return;

      const airplanePos = new THREE.Vector3(
        airplanePositionRef.current.x,
        airplanePositionRef.current.y,
        airplanePositionRef.current.z
      );

      const rotationSpeed = 2 * delta;
      if (rotateLeftAirplane.current) cameraYawAirplane.current -= rotationSpeed;
      if (rotateRightAirplane.current) cameraYawAirplane.current += rotationSpeed;
      if (rotateUpAirplane.current) cameraPitchAirplane.current += rotationSpeed;
      if (rotateDownAirplane.current) cameraPitchAirplane.current -= rotationSpeed;

      const maxPitch = Math.PI / 3;
      const minPitch = -Math.PI / 3;
      cameraPitchAirplane.current = Math.max(minPitch, Math.min(maxPitch, cameraPitchAirplane.current));

      const distance = 30;
      const x = distance * Math.sin(cameraYawAirplane.current) * Math.cos(cameraPitchAirplane.current);
      const y = distance * Math.sin(cameraPitchAirplane.current);
      const z = distance * Math.cos(cameraYawAirplane.current) * Math.cos(cameraPitchAirplane.current);
      const idealPosition = airplanePos.clone().add(new THREE.Vector3(x, y, z));

      camera.position.lerp(idealPosition, 0.05);
      camera.lookAt(airplanePos);
    } else if (isFirstPerson && rigidBodyRef.current && isLocked.current) {
      const currentVel = rigidBodyRef.current.linvel();
      const direction = new THREE.Vector3();
      const frontVector = new THREE.Vector3();
      const sideVector = new THREE.Vector3();

      frontVector.set(0, 0, moveBackward.current ? 1 : moveForward.current ? -1 : 0);
      sideVector.set(moveLeft.current ? -1 : moveRight.current ? 1 : 0, 0, 0);

      frontVector.applyQuaternion(camera.quaternion);
      sideVector.applyQuaternion(camera.quaternion);

      frontVector.y = 0;
      sideVector.y = 0;

      frontVector.normalize();
      sideVector.normalize();

      direction.addVectors(frontVector, sideVector);
      if (direction.length() > 0) {
        direction.normalize();
      }

      const dampingFactor = 0.85;
      const newXVel = currentVel.x * dampingFactor;
      const newZVel = currentVel.z * dampingFactor;

      if (direction.length() > 0) {
        const moveForce = direction.multiplyScalar(moveSpeed.current * delta);

        rigidBodyRef.current.setLinvel(
          {
            x: newXVel + moveForce.x,
            y: currentVel.y,
            z: newZVel + moveForce.z,
          },
          true
        );
      } else {
        rigidBodyRef.current.setLinvel(
          {
            x: newXVel,
            y: currentVel.y,
            z: newZVel,
          },
          true
        );
      }

      const pos = rigidBodyRef.current.translation();
      camera.position.set(pos.x, pos.y + cameraOffsetY, pos.z);

      if (Math.abs(currentVel.y) < 0.1) {
        onGround.current = true;
      } else {
        onGround.current = false;
      }
    } else if (isThirdPerson) {
      if (!playerPositionRef.current) return;

      const controlledPos = new THREE.Vector3(
        playerPositionRef.current.x,
        playerPositionRef.current.y,
        playerPositionRef.current.z
      );

      const rotationSpeed = 2 * delta;
      if (rotateLeft.current) cameraYaw.current -= rotationSpeed;
      if (rotateRight.current) cameraYaw.current += rotationSpeed;
      if (rotateUp.current) cameraPitch.current += rotationSpeed;
      if (rotateDown.current) cameraPitch.current -= rotationSpeed;

      const maxPitch = Math.PI / 3;
      const minPitch = -Math.PI / 3;
      cameraPitch.current = Math.max(minPitch, Math.min(maxPitch, cameraPitch.current));

      const distance = 50;
      const x = distance * Math.sin(cameraYaw.current) * Math.cos(cameraPitch.current);
      const y = distance * Math.sin(cameraPitch.current);
      const z = distance * Math.cos(cameraYaw.current) * Math.cos(cameraPitch.current);
      const idealPosition = controlledPos.clone().add(new THREE.Vector3(x, y, z));

      camera.position.lerp(idealPosition, 0.05);
      camera.lookAt(controlledPos);
    }
  });

  useEffect(() => {
    if (!blockList) return;
    const currentCameraBlock = blockList.find((block) => block.flow === 'camera');

    if (!currentCameraBlock) {
      console.log("Camera found: false");
      console.log("Default camera loaded: true");
      // Default camera properties are already set by Canvas component.
      // Optionally, you can explicitly set default camera properties here if needed.
      camera.fov = 75;
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.near = 0.1;
      camera.far = 1000;
      camera.position.set(...initialPosition); // Ensure default position is used
      camera.lookAt(0, 0, 0); // Default lookAt target
      camera.updateProjectionMatrix();
      console.log("Camera Position (Default):", camera.position.toArray()); // Log default position as array
      return; // Exit to prevent API camera settings from running
    } else {
      console.log("Camera found: true");
      console.log("API camera loaded");
    }


    camera.fov = currentCameraBlock.object?.fov || 75;
    camera.aspect = currentCameraBlock.object?.aspect || window.innerWidth / window.innerHeight;
    camera.near = currentCameraBlock.object?.near || 0.1;
    camera.far = currentCameraBlock.object?.far || 1000;

    camera.updateProjectionMatrix();

    if (currentCameraBlock.lookAtTarget && Array.isArray(currentCameraBlock.lookAtTarget)) {
      camera.lookAt(...currentCameraBlock.lookAtTarget);
    } else {
      camera.lookAt(0, 0, 0); // Default lookAt if not specified in API
    }

    // Set position from object.position if available, otherwise fallback to block.position
    const cameraPosition = currentCameraBlock.object?.position || currentCameraBlock.position || initialPosition;
    camera.position.set(...cameraPosition);
    console.log("Camera Position (API):", camera.position.toArray()); // Log API position as array


  }, [blockList, camera, initialPosition]); // Added initialPosition to dependency array

  if (!blockList) return null;
  const currentCameraBlock = blockList.find((block) => block.flow === 'camera');
  if (!currentCameraBlock) {
    console.log("Rendering OrbitControls as fallback");
    return <OrbitControls />; // Fallback to OrbitControls if no camera block at all and no default handling in useEffect
  }

  const isFirstPerson = currentCameraBlock?.object?.type === 'firstPerson';
  const isThirdPerson = currentCameraBlock?.object?.type === 'thirdPerson';
  const isPerspectiveCamera = currentCameraBlock?.object?.type === 'PerspectiveCamera'; // New camera type check
  const airplaneBlockExists = blockList.some((block) => block.flow === 'airplane');
  const orbitControlsEnabled = currentCameraBlock.orbitControlsEnabled;

  if (airplaneBlockExists) {
    console.log("Rendering Airplane Camera Rig");
    return (
      <RigidBody
        ref={airplaneRigidBodyRef}
        position={[0, 5, 0]}
        colliders={false}
        type="dynamic"
        lockRotations={false}
        mass={50}
        restitution={0.0}
        friction={0.2}
        linearDamping={0.1}
        angularDamping={0.5}
      >
        <mesh ref={airplaneRef} visible={false}>
          <boxGeometry args={[2, 0.5, 3]} />
          <meshBasicMaterial color="blue" wireframe />
        </mesh>
      </RigidBody>
    );
  } else if (isFirstPerson) {
    console.log("Rendering First Person Camera Rig");
    return (
      <>
        <RigidBody
          ref={rigidBodyRef}
          position={position}
          colliders="capsule"
          type="dynamic"
          lockRotations
          mass={1}
          restitution={0.1}
          friction={0.5}
          linearDamping={0.5}
          angularDamping={0.9}
          onCollisionEnter={(e) => {
            if (e.normal.y > 0.5) {
              onGround.current = true;
            }
          }}
        >
          <mesh ref={playerRef} visible={false}>
            <capsuleGeometry args={[0.5, 1, 16, 16]} />
            <meshBasicMaterial color="red" wireframe />
          </mesh>
        </RigidBody>

        <PointerLockControls
          ref={controlsRef}
          domElement={gl.domElement}
          onLock={() => {
            console.log('Pointer lock activated');
            isLocked.current = true;
          }}
          onUnlock={() => {
            console.log('Pointer lock deactivated');
            isLocked.current = false;
            moveForward.current = false;
            moveBackward.current = false;
            moveLeft.current = false;
            moveRight.current = false;
          }}
        />

        {!isLocked.current && (
          <mesh
            position={[0, 0, -1]}
            onClick={() => {
              if (controlsRef.current) {
                controlsRef.current.lock();
              }
            }}
          >
            <planeGeometry args={[100, 100]} />
            <meshBasicMaterial transparent={true} opacity={0} />
          </mesh>
        )}
      </>
    );
  } else if (isThirdPerson) {
    console.log("Rendering Third Person Camera Rig");
    return null;
  }  else if (isPerspectiveCamera) {
    console.log("Rendering PerspectiveCamera with OrbitControls");
    return <OrbitControls />;
  } else if (orbitControlsEnabled) {
    console.log("Rendering OrbitControls as configured");
    return <OrbitControls />;
  } else {
    console.log("No specific camera or controls rendered (null)");
    return null;
  }
}

export default Sim;