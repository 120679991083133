// Skybox.js
import React from 'react';
import { Environment, Sky } from '@react-three/drei';
import * as THREE from 'three'; // Import THREE

function Skybox({ block }) {
  console.log("Skybox Component Rendered"); // Log when the component renders
  console.log("Skybox Block Prop:", block); // Log the block prop

  if (!block) {
    console.log("No block prop received, returning null"); // Log if no block
    return null; // Or a default sky if needed
  }

  if (block.type === 'skybox') {
    console.log("Rendering Skybox Environment"); // Log before rendering Environment
    return (
      <Environment
        files={block.url}
      

      
      />
    );
  } else if (block.type === 'sky') {
    console.log("Rendering Sky Component"); // Log before rendering Sky
    return (
      <Sky
        distance={450000}
        sunPosition={[0, 1, 0]}
        inclination={0}
        azimuth={0.25}
    
      />
    );
  } else {
    console.log("Block type is not skybox or sky, returning null"); // Log if type is not recognized
    return null; // Or handle other sky types or default
  }
}

export default Skybox;