// SFX.js
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useThree } from '@react-three/fiber';
import {
  EffectComposer,
  Bloom,
  ChromaticAberration,
  DepthOfField,
  Noise,
  Vignette,
  SMAA,
  ToneMapping
} from '@react-three/postprocessing';
import { LUT3DEffect, BlendFunction, ToneMappingMode } from 'postprocessing';
import * as THREE from 'three';

function SFX({ blockList }) {
  const { scene, camera } = useThree();

  const sfxBlocks = useMemo(() => {
    return blockList ? blockList.filter(block => block.flow === 'sfx' && block.enabled !== false) : [];
  }, [blockList]);

  const [loadedLUTTextures, setLoadedLUTTextures] = useState({});

  const effectBlocks = useMemo(() => {
    const blocks = {};
    if (sfxBlocks.length > 0) {
      sfxBlocks.forEach(block => {
        if (block.flow === 'sfx') {
          if (!blocks[block.type]) {
            blocks[block.type] = [];
          }
          blocks[block.type].push(block);
        }
      });
    }
    return blocks;
  }, [sfxBlocks]);


  useEffect(() => {
    const lutBlocks = effectBlocks.lut || [];
    if (lutBlocks.length > 0) {
      const textureLoader = new THREE.TextureLoader();

      lutBlocks.forEach((block) => {
        if (block.type === 'lut') {
          const url = block.properties.url;
          if (url && !loadedLUTTextures[url]) {
            textureLoader.load(url, (texture) => {
              setLoadedLUTTextures(prev => ({
                ...prev,
                [url]: texture
              }));
            }, undefined, (error) => {
              console.error(`Error loading LUT texture from URL: ${url}`, error);
            });
          }
        }
      });
    }
  }, [effectBlocks, loadedLUTTextures]);

  const renderEffects = useCallback(() => {
    const effects = [];

    const lutBlocks = effectBlocks.lut || [];
    lutBlocks.forEach((block, index) => {
      if (block.type === 'lut') {
        const url = block.properties.url;
        const lutTexture = loadedLUTTextures[url];

        if (lutTexture && lutTexture instanceof THREE.Texture) {
          effects.push(
            <primitive
              key={`lut-${index}`}
              object={new LUT3DEffect(lutTexture, {
                intensity: block.properties.intensity || 1.0,
                blendFunction: BlendFunction.NORMAL
              })}
              dispose={null}
            />
          );
        }
      }
    });

    const bloomBlocks = effectBlocks.bloom || [];
    bloomBlocks.forEach((block, index) => {
      if (block.type === 'bloom') {
        effects.push(
          <Bloom
            key={`bloom-${index}`}
            intensity={block.properties.intensity || 1.0}
            luminanceThreshold={block.properties.luminanceThreshold || 0.9}
            luminanceSmoothing={block.properties.luminanceSmoothing || 0.025}
            blendFunction={BlendFunction.SCREEN}
          />
        );
      }
    });

    const chromaticBlocks = effectBlocks.chromaticAberration || [];
    chromaticBlocks.forEach((block, index) => {
      if (block.type === 'chromatic') {
        effects.push(
          <ChromaticAberration
            key={`chromatic-${index}`}
            offset={new THREE.Vector2(
              block.properties.offsetX || 0.005,
              block.properties.offsetY || 0.005
            )}
            radialModulation={block.properties.radialModulation || false}
            modulationOffset={block.properties.modulationOffset || 0.0}
          />
        );
      }
    });

    const dofBlocks = effectBlocks.depthOfField || [];
    dofBlocks.forEach((block, index) => {
      if (block.type === 'depthOfField') {
        effects.push(
          <DepthOfField
            key={`dof-${index}`}
            focusDistance={block.properties.focusDistance || 0.0}
            focalLength={block.properties.focalLength || 0.05}
            bokehScale={block.properties.bokehScale || 2.0}
          />
        );
      }
    });

    const noiseBlocks = effectBlocks.noise || [];
    noiseBlocks.forEach((block, index) => {
      if (block.type === 'noise') {
        effects.push(
          <Noise
            key={`noise-${index}`}
            premultiply={block.properties.premultiply || true}
            blendFunction={block.properties.colorOnly ? BlendFunction.COLOR : BlendFunction.OVERLAY}
          />
        );
      }
    });

    const vignetteBlocks = effectBlocks.vignette || [];
    vignetteBlocks.forEach((block, index) => {
      if (block.type === 'vignette') {
        effects.push(
          <Vignette
            key={`vignette-${index}`}
            darkness={block.properties.darkness || 0.5}
            offset={block.properties.offset || 0.5}
          />
        );
      }
    });

    const smaaBlocks = effectBlocks.smaa || [];
    if (smaaBlocks.length > 0) {
      smaaBlocks.forEach((block, index) => {
        if (block.type === 'smaa') {
          effects.push(<SMAA key="smaa" />);
        }
      });
    }

    const toneMappingBlocks = effectBlocks.toneMapping || [];
    toneMappingBlocks.forEach((block, index) => {
      if (block.type === 'toneMapping') {
        const mode = block.properties.mode || 'reinhard';
        const modeMap = {
          'reinhard': ToneMappingMode.REINHARD,
          'reinhard2': ToneMappingMode.REINHARD2,
          'aces': ToneMappingMode.ACES_FILMIC,
          'uncharted2': ToneMappingMode.UNCHARTED2,
          'cineon': ToneMappingMode.FILMIC,
        };
        effects.push(
          <ToneMapping
            key={`toneMapping-${index}`}
            mode={modeMap[mode] || ToneMappingMode.REINHARD}
            adaptive={block.properties.adaptive || true}
            resolution={block.properties.resolution || 256}
            middleGrey={block.properties.middleGrey || 0.6}
            whitePoint={block.properties.whitePoint || 0.9}
          />
        );
      }
    });

    return effects;
  }, [effectBlocks, loadedLUTTextures]);


  const effectsToRender = useMemo(renderEffects, [renderEffects]);
  const hasEffects = effectsToRender.length > 0;

  if (!hasEffects) {
    return null;
  }

  return (
    <EffectComposer multisampling={8} enabled={true}>
      {effectsToRender}
    </EffectComposer>
  );
}

export default SFX;