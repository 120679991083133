import React from 'react';
import Player from './Players'; // Adjusted import path to match your naming
import Airplane from './Airplane';
import Boat from './Boat';

function Characters({ blockList }) {
  const playerBlocks = blockList ? blockList.filter((block) => block.flow === 'player') : [];
  const airplaneBlocks = blockList ? blockList.filter((block) => block.flow === 'airplane') : [];
  const boatBlocks = blockList ? blockList.filter((block) => block.flow === 'boat') : [];

  console.log('Player Blocks to Render:', playerBlocks);
  console.log('Airplane Blocks to Render:', airplaneBlocks);
  console.log('Boat Blocks to Render:', boatBlocks);

  return (
    <>
      {playerBlocks.map((block) => (
        <Player key={block._id} block={block} />
      ))}
      {airplaneBlocks.map((block) => (
        <Airplane key={block._id} block={block} />
      ))}
      {boatBlocks.map((block) => (
        <Boat key={block._id} block={block} />
      ))}
    </>
  );
}

export default Characters;