import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { RigidBody, CapsuleCollider } from '@react-three/rapier';

function Player({ block }) {
  const gltf = useGLTF(block.geometry.url);
  const playerRef = useRef();
  const rigidBodyRef = useRef();
  const animationMixerRef = useRef();
  const animationsMapRef = useRef({});
  const [currentAnimation, setCurrentAnimation] = useState('Idle');
  const moveDirection = useRef(new THREE.Vector3());
  const speed = useRef(0);
  const { camera } = useThree();
  const canJump = useRef(true);
  const jumpForce = useRef(block.behavior?.behaviorData?.jumpForce || 8); // Use jumpForce from block data or default to 8
  const currentPositionRef = useRef({x: 0, y: 0, z: 0, rotation: 0});

  const setupAnimations = useCallback(() => {
    if (gltf && block.animation && block.animation.animations) {
      animationMixerRef.current = new THREE.AnimationMixer(gltf.scene);
      block.animation.animations.forEach(anim => {
        if (gltf.animations) {
          const clip = THREE.AnimationClip.findByName(gltf.animations, anim.clipName);
          if (clip) {
            animationsMapRef.current[anim.name] = animationMixerRef.current.clipAction(clip);
            if (anim.playOnLoad) {
              animationsMapRef.current[anim.name].play();
              setCurrentAnimation(anim.name);
            }
          } else {
            console.warn(`Animation clip "${anim.clipName}" not found in GLTF for animation "${anim.name}".`);
          }
        } else {
          console.warn(`No animations found in GLTF for block "${block.name}".`);
        }
      });

      if (block.animation.defaultAnimation && animationsMapRef.current[block.animation.defaultAnimation] && !block.animation.animations.find(anim => anim.name === block.animation.defaultAnimation)?.playOnLoad) {
        animationsMapRef.current[block.animation.defaultAnimation].play();
        setCurrentAnimation(block.animation.defaultAnimation);
      }
    }
  }, [gltf, block.animation]);

  useEffect(() => {
    if (gltf) {
      console.log("GLTF model loaded successfully for player:", block.name); // Added log for successful load
      setupAnimations();

      gltf.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.castShadow = block.castShadow;
          child.receiveShadow = block.receiveShadow;
        }
      });

      playerRef.current.add(gltf.scene);

      const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
      const modelHeight = boundingBox.getSize(new THREE.Vector3()).y;

      gltf.scene.rotation.set(0, Math.PI, 0);
      gltf.scene.position.set(0, 1, 0);

      playerRef.current.position.set(0, 0, 0);
      playerRef.current.scale.set(block.scale, block.scale, block.scale);

      console.log("Player Initial Position:", block.position);

      if (block.position) {
        currentPositionRef.current = {x: block.position[0], y: block.position[1], z: block.position[2], rotation: 0};
      }
    } else {
      console.error("Failed to load GLTF model for player:", block.name, block.geometry.url); // Added error log if gltf is null
    }
  }, [gltf, block, setupAnimations]);

  const playAnimation = useCallback((name) => {
    if (currentAnimation !== name && animationsMapRef.current[name]) {
      const fadeDuration = 0.2;
      animationsMapRef.current[currentAnimation]?.fadeOut(fadeDuration);
      animationsMapRef.current[name]?.reset().fadeIn(fadeDuration).play();
      setCurrentAnimation(name);
    }
  }, [currentAnimation]);

  useFrame((state, delta) => {
    if (animationMixerRef.current) {
      animationMixerRef.current.update(delta);
    }

    const walkSpeed = block.behavior?.behaviorData?.walkSpeed || 5;
    const runSpeed = block.behavior?.behaviorData?.runSpeed || 10;
    const currentSpeed = speed.current;

    if (currentSpeed > 0 && rigidBodyRef.current) {
      const direction = new THREE.Vector3();

      const forward = new THREE.Vector3();
      camera.getWorldDirection(forward);
      forward.y = 0;
      forward.normalize();
      const right = new THREE.Vector3().crossVectors(camera.up, forward).normalize();

      direction.addScaledVector(forward, moveDirection.current.z);
      direction.addScaledVector(right, moveDirection.current.x);

      if (direction.length() > 0) {
        direction.normalize();

        const currentVel = rigidBodyRef.current.linvel();

        rigidBodyRef.current.setLinvel(
          { x: direction.x * currentSpeed, y: currentVel.y, z: direction.z * currentSpeed },
          true
        );

        const targetRotation = Math.atan2(direction.x, direction.z);
        playerRef.current.rotation.y = targetRotation;
        currentPositionRef.current.rotation = targetRotation;
      }

      playAnimation('Run');
    } else if (currentAnimation !== 'Punch'){ // Prevent idle animation interrupt punch animation immediately
      playAnimation('Idle');
    }

    if (rigidBodyRef.current) {
      const position = rigidBodyRef.current.translation();
      currentPositionRef.current.x = position.x;
      currentPositionRef.current.y = position.y;
      currentPositionRef.current.z = position.z;


      window.dispatchEvent(new CustomEvent('playerPositionUpdate', {
        detail: {
          position: currentPositionRef.current
        }
      }));

      const vel = rigidBodyRef.current.linvel();
      if (Math.abs(vel.y) < 0.1) {
        canJump.current = true;
      }
    }
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.code) {
        case 'Space':
          if (canJump.current && rigidBodyRef.current) {
            rigidBodyRef.current.applyImpulse({ x: 0, y: jumpForce.current, z: 0 }, true); // Use jumpForce.current
            canJump.current = false;
            playAnimation('Jump'); // Play jump animation
          }
          break;
        case 'KeyW': moveDirection.current.z = 1; speed.current = block.behavior?.behaviorData?.walkSpeed || 5; break; // Move away from camera
        case 'KeyS': moveDirection.current.z = -1; speed.current = block.behavior?.behaviorData?.walkSpeed || 5; break;  // Move towards camera
        case 'KeyA': moveDirection.current.x = -1; speed.current = block.behavior?.behaviorData?.walkSpeed || 5; break;
        case 'KeyD': moveDirection.current.x = 1; speed.current = block.behavior?.behaviorData?.walkSpeed || 5; break;
        case 'KeyX':
          playAnimation('Punch');
          break;
        default: return;
      }
    };

    const handleKeyUp = (event) => {
      switch (event.code) {
        case 'KeyW': if (moveDirection.current.z > 0) moveDirection.current.z = 0; break;
        case 'KeyS': if (moveDirection.current.z < 0) moveDirection.current.z = 0; break;
        case 'KeyA': if (moveDirection.current.x < 0) moveDirection.current.x = 0; break;
        case 'KeyD': if (moveDirection.current.x > 0) moveDirection.current.x = 0; break;
        default: return;
      }
      if (moveDirection.current.z === 0 && moveDirection.current.x === 0) speed.current = 0;
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [block.behavior?.behaviorData?.walkSpeed, playAnimation]); // Added playAnimation to dependency array

  const initialPosition = block.position || [0, 1, 0];

  return (
    <RigidBody
      ref={rigidBodyRef}
      position={initialPosition}
      type="dynamic"
      mass={2}
      restitution={0.2}
      friction={0.8}
      linearDamping={1.5}
      angularDamping={1.0}
      lockRotations={true}
      colliders={false}
      enabledTranslations={[true, true, true]}
      gravityScale={1.0}
      onCollisionEnter={(e) => {
        if (e.normal.y > 0.5) {
          canJump.current = true;
        }
      }}
      name={block.name}
    >
      <group ref={playerRef} visible={block.visible}> {/* visibility is controlled by block.visible */}
        <CapsuleCollider
          args={[0.6, 0.4]}
          position={[0, 0.9, 0]}
        />
      </group>
    </RigidBody>
  );
}

export default Player;