// Light.js
import React, { useMemo } from 'react';
import {
  DirectionalLight,
  AmbientLight,
  PointLight,
  SpotLight,
  HemisphereLight,
  Color,
  Vector3
} from 'three';

function Light({ blockList }) {
  const lightBlocks = useMemo(() => {
    const filteredBlocks = blockList ? blockList.filter(block => block.flow === 'light' && block.enabled !== false) : [];
    console.log("Light blocks found:", filteredBlocks.length); // Console log to check if light blocks are found
    return filteredBlocks;
  }, [blockList]);

  const defaultPosition = [0, 0, 0];

  return (
    <>
      {lightBlocks.map((block, index) => {
        console.log("Loading light:", block.object.type, block._id); // Console log to check which light is being loaded
        if (block.object.type === 'DirectionalLight') {
          const light = new DirectionalLight(block.object.color, block.object.intensity);
          let positionToSet = defaultPosition;
          if (Array.isArray(block.object.position) && block.object.position.length === 3) {
            positionToSet = block.object.position;
          } else {
            console.warn(`DirectionalLight with _id ${block._id} has invalid position:`, block.object.position, ". Loading default position [0,0,0]");
          }
          light.position.set(...positionToSet);
          light.castShadow = block.object.castShadow;

          if (block.object.shadow) {
            light.shadow.mapSize.width = block.object.shadow.mapSizeWidth || 1024;
            light.shadow.mapSize.height = block.object.shadow.mapSizeHeight || 1024;
            light.shadow.camera.near = block.object.shadow.cameraNear || 0.5;
            light.shadow.camera.far = block.object.shadow.cameraFar || 20;
            light.shadow.camera.left = block.object.shadow.cameraLeft || -5;
            light.shadow.camera.right = block.object.shadow.cameraRight || 5;
            light.shadow.camera.bottom = block.object.shadow.cameraBottom || -5;
            light.shadow.camera.top = block.object.shadow.cameraTop || 5;
            light.shadow.bias = block.object.shadow.bias || -0.005;
          }

          return (
            <directionalLight
              key={block._id}
              color={block.object.color}
              intensity={block.object.intensity}
              position={positionToSet}
              castShadow={block.object.castShadow}
              shadow={light.shadow}
            />
          );
        } else if (block.object.type === 'AmbientLight') {
          return (
            <ambientLight
              key={block._id}
              color={block.object.color}
              intensity={block.object.intensity}
            />
          );
        } else if (block.object.type === 'PointLight') {
          const light = new PointLight(block.object.color, block.object.intensity);
          let positionToSet = defaultPosition;
          if (Array.isArray(block.object.position) && block.object.position.length === 3) {
            positionToSet = block.object.position;
          } else {
            console.warn(`PointLight with _id ${block._id} has invalid position:`, block.object.position, ". Loading default position [0,0,0]");
          }
          light.position.set(...positionToSet);
          return (
            <pointLight
              key={block._id}
              color={block.object.color}
              intensity={block.object.intensity}
              position={positionToSet}
            />
          );
        } else if (block.object.type === 'SpotLight') {
          const light = new SpotLight(block.object.color, block.object.intensity);
          let positionToSet = defaultPosition;
          if (Array.isArray(block.object.position) && block.object.position.length === 3) {
            positionToSet = block.object.position;
          } else {
            console.warn(`SpotLight with _id ${block._id} has invalid position:`, block.object.position, ". Loading default position [0,0,0]");
          }
          light.position.set(...positionToSet);
          light.castShadow = block.object.castShadow;

          if (block.object.shadow) {
            light.shadow.mapSize.width = block.object.shadow.mapSizeWidth || 1024;
            light.shadow.mapSize.height = block.object.shadow.mapSizeHeight || 1024;
            light.shadow.camera.near = block.object.shadow.cameraNear || 0.5;
            light.shadow.camera.far = block.object.shadow.cameraFar || 20;
            light.shadow.camera.fov = block.object.shadow.cameraFov || 50;
            light.shadow.bias = block.object.shadow.bias || -0.005;
          }
          light.angle = block.object.angle !== undefined ? block.object.angle : Math.PI / 3;
          light.penumbra = block.object.penumbra !== undefined ? block.object.penumbra : 0;
          light.decay = block.object.decay !== undefined ? block.object.decay : 1;
          light.distance = block.object.distance !== undefined ? block.object.distance : 0;
          light.target.position.set(...(block.targetPosition || [0, 0, 0]));

          return (
            <spotLight
              key={block._id}
              color={block.object.color}
              intensity={block.object.intensity}
              position={positionToSet}
              castShadow={block.object.castShadow}
              shadow={light.shadow}
              angle={light.angle}
              penumbra={light.penumbra}
              decay={light.decay}
              distance={light.distance}
              target-position={block.targetPosition}
            />
          );
        } else if (block.object.type === 'HemisphereLight') {
          const skyColor = block.object.color;
          const groundColor = block.object.groundColor;
          const light = new HemisphereLight(skyColor, groundColor, block.object.intensity);
          let positionToSet = defaultPosition;
          if (Array.isArray(block.object.position) && block.object.position.length === 3) {
            positionToSet = block.object.position;
          } else {
            console.warn(`HemisphereLight with _id ${block._id} has invalid position:`, block.object.position, ". Loading default position [0,0,0]");
          }
          light.position.set(...positionToSet);
          return (
            <hemisphereLight
              key={block._id}
              skyColor={skyColor}
              groundColor={groundColor}
              intensity={block.object.intensity}
              position={positionToSet}
            />
          );
        }
        return null;
      })}
    </>
  );
}

export default Light;