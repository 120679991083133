import React, { useRef, useMemo, useEffect } from 'react';
import { extend, useThree, useLoader, useFrame } from '@react-three/fiber';
import { Water } from 'three-stdlib';
import * as THREE from 'three';
import { RigidBody, CuboidCollider } from '@react-three/rapier';

// Extend Three.js with the Water class
extend({ Water });

function OceanPlane({ block }) {
  const ref = useRef();
  const gl = useThree((state) => state.gl);
  const waterNormals = useLoader(THREE.TextureLoader, '/waternormals.jpeg');
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;

  // Default to a large plane if geometry args aren't specified
  const geom = useMemo(
    () => new THREE.PlaneGeometry(block.geometry?.args?.[0] || 10000, block.geometry?.args?.[1] || 10000),
    [block.geometry]
  );

  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      sunDirection: new THREE.Vector3(0, 1, 0), // Pointing upwards for better lighting
      sunColor: 0xffffff,
      waterColor: block.material?.color || 0x006994,
      distortionScale: block.material?.distortionScale || 3.7,
      fog: false,
      format: gl.encoding,
      alpha: 0.9, // Ensure slight transparency to see objects below
    }),
    [waterNormals, gl.encoding, block.material]
  );

  useFrame((state, delta) => {
    if (ref.current) {
      ref.current.material.uniforms.time.value += delta;
    }
  });

  // Default position at y = 0, flat plane
  const position = block.position || [0, 0, 0];

  return (
    <RigidBody type="fixed" position={position} rotation={[-Math.PI / 2, 0, 0]}>
      <water ref={ref} args={[geom, config]} />
      <CuboidCollider
        args={[block.geometry?.args?.[0] / 2 || 5000, 0.01, block.geometry?.args?.[1] / 2 || 5000]}
        restitution={0.5}
        friction={0.8}
      />
    </RigidBody>
  );
}

function Ocean({ blocks }) {
  const oceanBlocks = blocks || [];

  useEffect(() => {
    if (oceanBlocks.length > 0) {
      console.log('Ocean loaded with', oceanBlocks.length, 'blocks');
    }
  }, [oceanBlocks]);

  return (
    <>
      {oceanBlocks.map((block, index) => (
        <OceanPlane key={block._id || index} block={block} />
      ))}
    </>
  );
}

export default Ocean;